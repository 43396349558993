import { rp3Post } from './myAxios';

export const sendArrivalSms = async ({ phone, time, deliveryRef }) => {
  const res = await rp3Post({
    action: 'sendSMS',
    mobile_number: phone,
    message: `We expect to be with you within the next ${time} minutes.`,
    deliveryRef
  });
  return res;
};
