import { Workbox } from 'workbox-window';

const sw = new Workbox('/sw-esm.js');
sw.register();

export const getServiceWorker = () => sw;

/**
 * Send a message directly to the service worker
 * @param {*} message.type - the type of message to send
 * @param {*} message.payload - any data to pass through
 * @returns Promise
 */
export const messageSW = message => {
  return sw.messageSW(message);
};
