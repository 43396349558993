const startState = {
  feedback: {
    message: '',
    confirmed: true,
    type: 'error',
    beepTimestamp: 0,
    errorTimestamp: 0,
    acknowledgeTimestamp: 0
  }
};

export function feedback(store) {
  store.on('@init', () => startState);
  store.on('clearAll', () => startState);

  store.on('error', ({ feedback }, message) => ({
    feedback: { ...feedback, message, confirmed: false, type: 'error' }
  }));

  store.on('info', ({ feedback }, message) => ({ feedback: { ...feedback, message, confirmed: false, type: 'info' } }));

  store.on('feedback/confirmed', ({ feedback }) => ({ feedback: { ...feedback, confirmed: true, message: '' } }));

  store.on('audio/beep', ({ feedback }) => ({ feedback: { ...feedback, beepTimestamp: Date.now() } }));

  store.on('audio/error', ({ feedback }) => ({ feedback: { ...feedback, errorTimestamp: Date.now() } }));

  store.on('audio/acknowledge', ({ feedback }) => ({ feedback: { ...feedback, acknowledgeTimestamp: Date.now() } }));
}
