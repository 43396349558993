/* eslint-disable no-unused-vars */

import { rp3Get } from '../util/myAxios';
import { actions as deliveryActions } from './deliveries';

const startState = {
  manifest: {
    hasRoute: null,
    manifestNo: 0,
    van: '',
    openedOn: '',
    shipOn: '',
    status: '',
    note: '',
    error: false,
    flattenedStock: [],
    nonSerial: [],
    fullData: {},
    allScanned: false,
    isRefreshing: false,
    timestamp: 146715000000 // random date from long ago
  }
};

export const actions = {
  GET_ROUTE: 'manifest/getRoute',
  CLEAR_MANIFEST: 'manifest/clearManifest',
  VAN_SCAN: 'manifest/vanScan',
  REFRESH_DATA: 'manifest/refreshData'
};

const _actions = {
  POPULATE_ROUTE: 'manifest/_populateRoute',
  GET_MANIFEST: 'manifest/_getManifest',
  POPULATE_MANIFEST: 'manifest/_populateManifest',
  MANIFEST_ERROR: 'manifest/_error',
  VAN_SCAN_RESULT: 'manifest/_vanScanResult'
};

export const status = {
  PENDING: 'pending',
  ACTIVE: 'active',
  COMPLETE: 'complete'
};

export const itemStatus = {
  PENDING: 'pending',
  PICKED: 'picked',
  DESPATCHED: 'despatched',
  RECEIVED: 'received'
  //DELIVERY: 'delivery'
};

const NO_NETWORK_MESSAGE = 'No connection to server. Try again when connection improves.';

export function manifest(store) {
  store.on('@init', () => startState);
  store.on('clearAll', () => ({ manifest: { ...startState.manifest } }));

  store.on(actions.CLEAR_MANIFEST, () => {
    // one call to clear them all
    store.dispatch(deliveryActions.CLEAR_DROPS);
    return { manifest: Object.assign({}, startState.manifest) };
  });

  // the MAIN entry point for when refreshing data. Triggers functions in
  // the deliveries store as well
  store.on(actions.GET_ROUTE, async ({ user }) => {
    try {
      const response = await rp3Get({ ACTION: 'getRoute' });

      if (response.status === 200) {
        if (response.data.offline) {
          store.dispatch('info', NO_NETWORK_MESSAGE);
        } else if (response.data.hasRoute) {
          store.dispatch(_actions.POPULATE_ROUTE, response.data);
          store.dispatch(deliveryActions.POPULATE_DROPS, response.data);
          store.dispatch(_actions.GET_MANIFEST, response.data.manifestNo);
        } else {
          store.dispatch(_actions.POPULATE_ROUTE, { hasRoute: false });
          store.dispatch(deliveryActions.POPULATE_DROPS, []);
        }
      } else {
        throw new Error('Server responded with HTTP code ' + response.status);
      }
    } catch (err) {
      console.log(err);
      store.dispatch(_actions.MANIFEST_ERROR);
      store.dispatch(deliveryActions.POPULATE_DROPS, null);
      store.dispatch('error', 'Error updating manifest list: ' + err.message);
    }
  });

  // use the raw response to populate some core data
  store.on(_actions.POPULATE_ROUTE, ({ manifest }, data) => {
    if (data === null) return { manifest }; // no data, can't make any changes (shouldn't happen)

    return {
      manifest: {
        ...manifest,
        fullData: {},
        flattenedStock: [],
        hasRoute: data.hasRoute,
        manifestNo: data.manifestNo,
        van: data.van,
        openedOn: data.openedOn, // leaving these as YYYY-MM-DD strings internally
        shipOn: data.shipOn, // until there's a (possible) need to convert them
        status: data.status,
        note: data.note,
        timestamp: Date.now(),
        error: false,
        isRefreshing: false
      }
    };
  });

  // triggered by GET_ROUTE, retrieves the manifest data for on the van
  store.on(_actions.GET_MANIFEST, async ({ manifest, user }, manifestNo) => {
    try {
      const response = await rp3Get({
        ACTION: 'manifestDetail',
        manifestNo,
        showdels: 1,
        deliveries: 1
      });

      if (response.status === 200) {
        if (response.data.offline) {
          store.dispatch('info', NO_NETWORK_MESSAGE);
        } else {
          store.dispatch(_actions.POPULATE_MANIFEST, response.data);
        }
      } else {
        throw new Error('Server responded with HTTP code ' + response.status);
      }
    } catch (err) {
      console.log(err);
      store.dispatch(_actions.MANIFEST_ERROR);
      store.dispatch('error', 'Error updating manifest list for ' + manifestNo + ': ' + err.message);
    }
  });

  // called with the response of the manifestDetail request
  store.on(_actions.POPULATE_MANIFEST, ({ manifest }, data) => {
    if (data === null) return { manifest }; // no data, can't make any changes (shouldn't happen)

    // process the data in a way that will allow us to behave like the old app
    const flattenedStock = [];
    const nonSerial = [];

    // we process the items backwards so that things for delivery last show up first -
    // this helps with loading the van, putting the last things at the back of the van
    data.items.reverse().map(item => {
      item.stockNos.map(stock => {
        flattenedStock.push({
          orderNo: item.orderNo,
          deliveryRef: item.deliveryRef,
          stockNo: stock.stockNo,
          status: stock.status, // this is used for the barcode scanning section - we'll have to keep an eye on this
          serials: item.serials,
          quantity: item.quantity,
          ean: item.ean,
          saleRef: item.sale
        });
      });
    });

    return {
      manifest: { ...manifest, flattenedStock, nonSerial, fullData: data, allScanned: allScanned(flattenedStock) }
    };
  });

  // scan stock on to the van in the warehouse (most likely)
  store.on(actions.VAN_SCAN, async ({ manifest, user }, { value }) => {
    // make sure that value is a valid barcode

    const idx = manifest.flattenedStock.findIndex(item => item.stockNo === value && item.status === itemStatus.PICKED); //this should handle eans as well

    if (idx === -1) {
      // no match
      console.log('no match for ' + value);
      store.dispatch('audio/error');
      return { manifest }; // this won't actually make much difference, this is an async function after all
    }
    store.dispatch('audio/beep');

    const item = manifest.flattenedStock[idx];

    try {
      const response = await rp3Get({
        ACTION: 'despatchStockNo',
        manifestNo: manifest.manifestNo,
        deliveryRef: item.deliveryRef,
        stockNo: item.serials ? item.stockNo : item.ean,
        serials: item.serials,
        quantity: 1, //item.quantity, -- one at a time, obvs
        deliveries: 1
      });

      if (response.status === 200) {
        if (response.data.offline) {
          store.dispatch('info', NO_NETWORK_MESSAGE);
        } else if (response.data.ok) {
          store.dispatch(_actions.VAN_SCAN_RESULT, {
            itemIndex: idx,
            item,
            data: response.data
          });
          store.dispatch(deliveryActions.VAN_SCAN, { item, data: response.data });
        } else {
          throw new Error('Server responded with ' + JSON.stringify(response.data));
        }
      } else {
        throw new Error('Server responded with HTTP code ' + response.status);
      }
    } catch (err) {
      console.log(err);
      store.dispatch(_actions.MANIFEST_ERROR);
      store.dispatch('error', 'Error pushing item status change: ' + err.message);
    }
  });

  // called with the response of the despatchStockNo request
  store.on(_actions.VAN_SCAN_RESULT, ({ manifest }, { itemIndex, item, data }) => {
    // now update things in the data structure
    const manifestClone = { ...manifest };
    manifestClone.flattenedStock[itemIndex] = { ...item, status: itemStatus.DESPATCHED };

    // we also need to consider the raw fullData. this can probably be done in a more ES6y way!
    manifestClone.fullData.items.every(storedItem => {
      let notFound = true; // not the greatest name
      storedItem.stockNos.map(sn => {
        if (notFound && sn.stockNo === item.stockNo && sn.status !== itemStatus.DESPATCHED) {
          sn.status = itemStatus.DESPATCHED; //.DELIVERY; // different value to what was being expected
          notFound = false;
        }
        return sn;
      });
      return notFound;
    });

    manifestClone.allScanned = allScanned(manifestClone.flattenedStock);

    // and store
    return { manifest: manifestClone };
  });

  store.on(actions.REFRESH_DATA, ({ manifest }) => {
    // the proper way to handle the refresh button in the header

    if (manifest.isRefreshing) {
      return; // nothing to do
    }

    store.dispatch(actions.GET_ROUTE);
    return { manifest: { ...manifest, isRefreshing: true } };
  });

  store.on(actions.QUEUE_UPDATED, ({ manifest }) => {
    if (manifest.isRefreshing) {
      // gets set back to false in the route population
      store.dispatch(actions.GET_ROUTE);
    }
  });

  store.on(_actions.MANIFEST_ERROR, ({ manifest }) => ({ manifest: { ...manifest, error: true } }));
}

// see if everything has been set to 'despatched'
function allScanned(flattenedStock) {
  return flattenedStock.every(item => item.status === itemStatus.DESPATCHED); // && flattenedStock.length > 0;
}
