/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import { actions as manifestActions } from './manifest';
import { sendArrivalSms } from '../util/messaging';
import { DateTime } from 'luxon';
import rfdc from 'rfdc';
import { rp3Post, uploadPhoto } from '../util/myAxios';
import { hasRouteChanged, setLastRouteChange, purgeRouteChangeData } from '../util/updates';
import localforage from 'localforage';
import * as Sentry from '@sentry/browser';

// replacing failing lodash function with this
const cloneDeep = rfdc();

const startState = {
  deliveries: {
    drops: {},
    dropManifest: [],
    dropManifestIndex: 0,
    currentDrop: {
      customer: {},
      items: [],
      signed: false,
      scanned: false,
      notes: '',
      override: false,
      scannedBarcodes: [],
      requiredBarcodes: [],
      undeliverable: [],
      stabbyButtons: [],
      stabbedButtons: [],
      photos: [] // an array of objects (keys, UUIDs, blob urls) for localforage
    },
    completedDrops: [],
    upcomingDrops: []
  }
};

export const actions = {
  POPULATE_DROPS: 'deliveries/populateDrops',
  STORE_SIGNATURE: 'deliveries/storeSignature',
  UPDATE_SIGNATURE_NAME: 'deliveries/updateSignatureName',
  SIGNED: 'deliveries/signed',
  SCANNED: 'deliveries/scanned', // will have to remember we're scanning all the items
  STABBED: 'deliveries/stabbed', // I'm keeping this name
  COMPLETE: 'deliveries/complete',
  UNDELIVERABLE: 'deliveries/undeliverable',
  BULK_UNDELIVERABLE: 'deliveries/bulkUndeliverable',
  CLEAR_DROPS: 'deliveries/clearDrops',
  SEND_SMS: 'deliveries/sendSms',
  UPDATE_ADVANCED: 'deliveries/updateAdvanced',
  VAN_SCAN: 'deliveries/vanScan', // called in from the manifest department
  REORDER_DROPS: 'deliveries/reorderDrops',
  ADD_PHOTO: 'deliveries/addPhoto',
  REMOVE_PHOTO: 'deliveries/removePhoto',
  CHECK_FOR_PHOTOS: 'deliveries/checkForPhotos', // used when things have gone wrong somehow
  MISSING_RECORD_UPLOADED: 'deliveries/missingRecordUploaded', // ditto
  SET_ACTIVE_DROP_NO: 'deliveries/setActiveDropNo' // when opening a delivery
};

const _actions = {
  UPDATE_DROP_LOOKUP: 'deliveries/_updateDropLookup',
  SEND_TO_SERVER: 'deliveries/_sendToServer',
  SMS_SENT: 'deliveries/_smsSent',
  SEND_REORDERED_DROPS: 'deliveries/_sendReorderedDrops',
  DROPS_REORDERED: 'deliveries/_dropsReordered',
  ADD_LOST_PHOTO: 'deliveries/_addLostPhoto'
};

export const status = {
  PENDING: 'Pending Delivery',
  DELIVERED: 'Delivered',
  UNDELIVERABLE: 'Failed Delivery',
  PART_DELIVERED: 'Part Delivered'
};

export const itemStatus = {
  PENDING: 'pending',
  PICKED: 'picked', // for when we're scanning onto the van
  SCANNED: 'scanned',
  UNDELIVERABLE: 'undeliverable',
  DESPATCHED: 'despatched' // actually this is for scanning onto the van it seems
};

export const undeliverableReason = {
  nobody_home: 'Nobody home',
  address_not_found: 'Address not found',
  wrong_item: 'Wrong item',
  item_damaged: 'Item damaged',
  other_issue: 'Other issue (single item)',
  wrong_delivery_date: 'Wrong delivery date',
  other_issue_bulk: 'Other issue (whole delivery)'
};

const recordStatus = {
  EXISTING: 0,
  NEW: 1,
  FAILED: 2
};

const INITIAL_DROP_NO = 99999999;
const PHOTO_PREFIX = 'photo';
const DATA_PREFIX = 'data';

export function deliveries(store) {
  const clearDrops = () => {
    purgeRouteChangeData();
    return { deliveries: cloneDeep(startState.deliveries) };
  };

  store.on('@init', () => startState);
  store.on('clearAll', clearDrops);
  store.on(actions.CLEAR_DROPS, clearDrops);

  store.on(actions.POPULATE_DROPS, ({ deliveries }, data) => {
    if (data === null) return deliveries; // no data -> no change
    console.log(data);
    // rattle over the data and create drops accordingly. (data.drops is an array if present)
    let dropManifestIndex = INITIAL_DROP_NO; // as we go through we'll find the lowest number that hasn't been delivered
    const dropManifest = [];
    const drops = {};
    const date = getToday();

    // store the lastRouteChange value. Need to consider the possibility of race conditions if two
    // parts of the store are querying and potentially modifying this
    if (!hasRouteChanged(data.lastRouteChange)) {
      // nothing has changed, nothing to do
      toast.info('Tablet data up-to-date');
      return deliveries;
    }

    // we'll store this timestamp at the end of processing

    data.drops?.map(drop => {
      const requiredBarcodes = [];
      const specialInfo = []; // mop up the unscannables
      const stabbyButtons = []; // mop up the stabby buttons

      const items = drop.items.map(item => {
        // gather together the values of the barcodes we'll be scanning
        if (item.showStabbyButton) {
          stabbyButtons.push({ deliveryRef: item.deliveryRef, description: item.description });
        } else if (item.serials || (item.orderNo >= 990000 && item.ean === '')) {
          // so we catch callout orders which will need their jobsheet scanned
          // in this case it will be the stockNos we add to the required barcodes
          item.stockNos.forEach(record => {
            const stockNo = record.stockNo.toString(); // helpful - strings have a .toString as well
            if (stockNo !== '' && item.quantity >= 0 /* for swap in/out */) {
              requiredBarcodes.push(stockNo);
            } else {
              specialInfo.push(item.description);
            }
          });
        } else {
          // no serials -> scan the ean. nothing about quantities here
          const ean = item.ean.toString().trim();
          if (ean !== '') {
            // empty string is the woolacotts installation etc, so nothing to scan.
            // (this may be redundant now that we have the stabby buttons)
            for (let i = 0; i < item.quantity; i++) {
              requiredBarcodes.push(ean);
            }
          } else {
            specialInfo.push(item.description);
          }
        }

        return {
          orderNo: item.orderNo,
          model: item.model,
          make: item.make,
          description: item.description,
          quantity: item.quantity,
          location: item.location,
          deliveryRef: item.deliveryRef,
          deliveryDate: item.deliveryDate,
          deliveryTimeSlot: item.deliveryTimeSlot,
          installationRequired: item.installationRequired,
          showStabbyButton: item.showStabbyButton,
          serials: item.serials,
          ean: item.ean,
          stockNos: [...item.stockNos] // a change to be aware of - stockNos is now an array of objects
        };
      });

      if (drop.routeSequence < dropManifestIndex && drop.dropStatus === status.PENDING)
        dropManifestIndex = drop.routeSequence;

      //parse the phone numbers from the string provided
      const phones = drop.phone
        .split('/')
        .map(record => record.replace(/\D/g, ''))
        .filter(phone => phone !== '');

      if (phones[0] === phones[1]) {
        phones.pop(); // remove the duplicate phone number if there is one
      }

      // storage of the data has changed a little bit...

      drops[drop.dropNo] = {
        dropNo: drop.dropNo,
        date,
        status: drop.dropStatus,
        requiredBarcodes,
        specialInfo,
        rescheduled: false, // will get changed to true if needed in categorisation
        scannedBarcodes: [],
        undeliverable: [],
        stabbyButtons,
        stabbedButtons: [],
        photos: [],
        customer: {
          ref: drop.customerCode,
          name: drop.customerName,
          address: drop.customerAddress,
          postcode: drop.customerPostCode,
          phones,
          smsCount: 0,
          notes: drop.extraDetails
        },
        sale: {
          orderNo: drop.saleDetails.orderNo,
          orderDate: drop.saleDetails.orderDate,
          staffId: drop.saleDetails.staffID,
          deliveringBranch: drop.saleDetails.deliveringBranch,
          deliveryNotes: drop.saleDetails.deliveryNotes.trim(),
          customerNotes: drop.saleDetails.customerNotes.trim()
        },
        items,
        signatureImage: '',
        signatureName: '',
        signed: drop.signed, // don't need the filename as well, although that would be possible
        scanned: drop.scanned,
        notes: '', // might be worth taking notes from the drop rather than this being blank to start...?
        override: false,
        isDeliverable: isDropDeliverable(items)
      };

      dropManifest[drop.routeSequence] = drop.dropNo; // we have the drops indexed from 0 now to make this easier
    });

    // let's try making things less sparse
    const currentDropNo = dropManifest[dropManifestIndex];
    const denseManifest = dropManifest.filter(val => val); //filter automatically removes emptiness
    const denseManifestIndex = denseManifest.indexOf(currentDropNo);

    const categorised = categoriseDrops({
      drops,
      dropManifest: denseManifest,
      dropManifestIndex: denseManifestIndex,
      completedDrops: deliveries.completedDrops,
      removeMatchingFails: true
    });

    if (dropManifestIndex === INITIAL_DROP_NO) {
      console.log('No drops');
    } else {
      setCurrentDropNo(currentDropNo);
    }
    const rtnObj = {
      deliveries: {
        ...deliveries,
        drops,
        dropManifest: denseManifest,
        dropManifestIndex: denseManifestIndex,
        ...categorised
      }
    };

    // finally, store the timestamp
    setLastRouteChange(data.lastRouteChange);

    return rtnObj;
  });

  store.on(actions.STORE_SIGNATURE, ({ deliveries }, { signatureImage }) => {
    const currentDrop = getCurrentDrop(deliveries);
    currentDrop.signatureImage = signatureImage;
    currentDrop.signed = true;
    const rtnObj = setCurrentDrop(deliveries, currentDrop);
    return rtnObj;
  });

  store.on(actions.SCANNED, ({ deliveries }, payload) => {
    const currentDrop = getCurrentDrop(deliveries);
    const { value, from, reason, notes, override } = payload;
    let { to } = payload; // needs to be mutable for later on

    Sentry.addBreadcrumb({
      category: 'deliveriesStore',
      message: 'Scanning data',
      level: 'info',
      data: payload
    });

    Sentry.addBreadcrumb({
      category: 'deliveriesStore',
      message: 'currentDrop clone',
      level: 'info',
      data: currentDrop
    });

    let fromList, toList;

    if (from === itemStatus.SCANNED) {
      fromList = currentDrop.scannedBarcodes;
    } else if (from === itemStatus.UNDELIVERABLE) {
      fromList = currentDrop.undeliverable;
    } else {
      fromList = currentDrop.requiredBarcodes; // default
    }

    if (to === itemStatus.UNDELIVERABLE) {
      toList = currentDrop.undeliverable;
    } else if (to === itemStatus.PENDING) {
      toList = currentDrop.requiredBarcodes;
    } else {
      toList = currentDrop.scannedBarcodes;
      to = itemStatus.SCANNED; // need this value later
    }

    // if the value can't be found in the fromList then this is a bad scan and we can't continue
    const barcodeIdx = fromList.indexOf(value);

    if (barcodeIdx === -1) {
      // only alert Sentry if it's a purely numeric value
      if (value.match(/^\d$/)) {
        Sentry.captureMessage('No match for ' + value);
      }

      store.dispatch('audio/error');
      return { deliveries };
    }

    store.dispatch('audio/beep');
    // remove from fromList
    fromList.splice(barcodeIdx, 1);

    // add to toList
    toList.push(value);

    // update the item record as well. this isn't so easy as we need to look in the stockNos
    // of every item to match it...
    let notFound = true; // seemingly the wrong way round, I know
    currentDrop.items.forEach(item => {
      if (notFound) {
        item.stockNos.forEach(record => {
          if (notFound && record.stockNo === value && record.status !== to) {
            record.status = to; // this is why it had to be mutated
            notFound = false; // so we don't keep processing once we've got a hit
            if (reason) {
              record.reason = reason; // for flagging why something is undeliverable
            }
          }
        });
      }
    });

    // if we have a notes field, add it to the end of the notes in currentDrop
    if (notes) {
      currentDrop.notes += '\n' + notes;
      currentDrop.notes.trim();
    }

    // if we have an override field, update that as well
    if (override !== undefined) {
      currentDrop.override = override;
    }

    // update the higher level scanned flag
    currentDrop.scanned = isEverythingScanned(currentDrop);
    const rtnObj = setCurrentDrop(deliveries, currentDrop);
    return rtnObj;
  });

  store.on(actions.COMPLETE, ({ deliveries, manifest }, payload) => {
    // STEP 1: Build a payload to send to the server, and dispatch it.
    const currentDrop = payload?.currentDrop ?? getCurrentDrop(deliveries);
    const _status = payload?.status ?? selectCompleteStatus(currentDrop.items);
    const _signed = payload?.signed ?? true;
    const _scanned = payload?.scanned ?? true;

    const uploadObj = {
      manifestNo: manifest.manifestNo,
      action: 'updateRoute',
      drop: {
        dropNo: currentDrop.dropNo,
        orderNo: currentDrop.sale?.orderNo,
        status: _status,
        items: currentDrop.items.map(item => ({
          deliveryRef: item.deliveryRef,
          serials: item.serials,
          ean: item.ean,
          stockNos: item.stockNos.slice()
        })),
        notes: currentDrop.notes,
        overrideConfirmation: currentDrop.override
      }
    };

    // only add the signature image if it's actually signed for (so not for outright undeliverable drops)
    if (currentDrop?.signed) {
      uploadObj.drop.signature = currentDrop.signatureImage;
      uploadObj.drop.signatureName = currentDrop.signatureName || currentDrop.customer.name;
    }

    // keep this block of data in localforage in case it all goes wrong - also helpful to
    // make sure the repeating job bug can get caught. (I think it's in this area where the
    // bug manifests itself - the switching between async and sync is a perfect storm)
    const key = `${DATA_PREFIX}:${getToday()}:${uploadObj.drop.dropNo}:${uploadObj.manifestNo}`;
    localforage.setItem(key, uploadObj).catch(err => {
      console.error('Error saving ' + key, err);
    });
    lsSet(uploadObj.drop.dropNo, _status === status.DELIVERED); // store the dropNo and successfulness
    store.dispatch(_actions.SEND_TO_SERVER, { rp3data: uploadObj, photos: currentDrop.photos });

    // STEP 2: Increase the dropManifestIndex, re-categorise and return
    //let { dropManifestIndex } = deliveries;
    const currentReference = currentDrop.dropNo;
    let dropManifestIndex = deliveries.dropManifest.indexOf(currentReference);
    dropManifestIndex++;

    // reworked what with there not being a currentDrop as such any more
    const categorised = categoriseDrops({
      drops: deliveries.drops,
      dropManifestIndex,
      dropManifest: deliveries.dropManifest,
      completedDrops: deliveries.completedDrops
    });

    // currentDrop, although holding not-the-clone-of-the-current-drop-really right now,
    // can be used to update the status (so not everything is pending all the time)
    // and pushed back in accordingly.

    currentDrop.status = _status;
    currentDrop.rescheduled = false;
    const rtnObj = setCurrentDrop(deliveries, currentDrop);
    rtnObj.deliveries.dropManifestIndex = dropManifestIndex;
    rtnObj.deliveries.completedDrops = categorised.completedDrops;
    rtnObj.deliveries.upcomingDrops = categorised.upcomingDrops;
    rtnObj.deliveries.currentDrop = categorised.currentDrop;

    // now that we've got the return object ready we can change the current drop number.
    // to change it before we start filling in details would be bad.
    // (I speak from experience and much anguish...)
    if (categorised.currentDrop === null) {
      // we must've got to the end of the manifest (or there's duff data)
      categorised.currentDrop = cloneDeep(startState.deliveries.currentDrop);
      setCurrentDropNo(0);
    } else if (categorised.currentDrop.dropNo) {
      // we're moving on to the next drop
      console.log(currentReference + ' -> ' + categorised.currentDrop.dropNo);
      setCurrentDropNo(categorised.currentDrop.dropNo);
    }

    return rtnObj;
    //return { ...newDeliveries, dropManifestIndex, ...categorised };
  });

  store.on(_actions.SEND_TO_SERVER, async ({ deliveries }, { rp3data, photos }) => {
    // deliveries probably won't get used... or will it? we shall see.
    try {
      const res = await rp3Post(rp3data);
      // inspect the response to ensure there's nothing funky coming back
      // and/or that there's an update to the manifest

      if (res.status !== 200) {
        throw new Error('Server responded with HTTP code ' + res.status);
      }

      if (!res.data.ok) {
        console.log(res.data);
        throw new Error('Server responded with ' + JSON.stringify(res.data));
      }

      // see if we'll need to update the manifest (ie there's been changes made in RP3)
      if (res.data.lastRouteChange && hasRouteChanged(res.data.lastRouteChange)) {
        // new method
        toast.info('Route updated - fetching refreshed data');
        store.dispatch(manifestActions.GET_ROUTE);
      } else if (res.data.getRoute) {
        // old method
        toast.info('Route updated - fetching refreshed data');
        store.dispatch(manifestActions.GET_ROUTE);
      }

      if (res.data.queued) {
        toast.info(`Order number ${rp3data.drop.orderNo} queued for upload when network is available`);
      } else {
        toast.info(`Order number ${rp3data.drop.orderNo} sent to server`);
      }

      // start uploading the photos

      photos.forEach(async photoRecord => {
        const barcode = rp3data.drop.orderNo;
        const photo = await localforage.getItem(photoRecord.key);
        const payload = {
          uuid: photoRecord.uuid,
          barcode,
          photo
        };
        const photoRes = await uploadPhoto(payload);
        if (photoRes.data.queued) {
          toast.info(`Photo(s) for order number ${barcode} queued for upload when network is available`, {
            toastId: `photo.${barcode}`
          });
        } else {
          toast.info(`Photo(s) for order number ${barcode} sent to server`, {
            toastId: `photo.${barcode}`
          });
        }
      });
    } catch (err) {
      // some kind of failure - maybe a network issue. we'll pop this into
      // a failed queue thing which can be triggered later
      console.log(err);
      toast.error(
        `Error contacting server for order number ${rp3data.drop.orderNo}. Details are saved and will be uploaded later.`
      );
    } finally {
      // actually move on to the next record here perhaps?
      // no - this wouldn't get called until after the photos are uploaded and
      // there could be a bazillion of them

      // so... how about this? Look at the delivery that is currentDrop and see
      // if there's a match with localforage. if there is, something has gone
      // horribly wrong but at least we'll know about it

      const { currentDrop } = deliveries;
      const key = `${DATA_PREFIX}:${getToday()}:${currentDrop.dropNo}:${currentDrop.manifestNo}`;
      localforage.getItem(key).then(data => {
        if (data) {
          console.log(
            `We've got a problem! ${key} is already in localforage so the progression to the next delivery has failed :(((`
          );

          // triage the problem
        }
      });
    }
  });

  store.on(actions.BULK_UNDELIVERABLE, async ({ deliveries }, payload) => {
    // none of the items will be able to be delivered, so iterate over them and
    // form the array that needs to go back to the server.
    // the payload will contain the reason given for the non-delivery together
    // with the (currently required) notes.

    const currentDrop = getCurrentDrop(deliveries);

    currentDrop.items.forEach(item => {
      item.stockNos.forEach(record => {
        record.status = itemStatus.UNDELIVERABLE;
        record.reason = payload.reason; // for flagging why something is undeliverable
      });
    });
    currentDrop.undeliverable = [...currentDrop.requiredBarcodes];
    currentDrop.requiredBarcodes = [];

    currentDrop.stabbyButtons.forEach(btn => {
      btn.accepted = false;
      currentDrop.stabbedButtons.push({ ...btn });
    });
    currentDrop.stabbyButtons = [];

    currentDrop.notes = [payload.notes, currentDrop.notes].join('\n');
    store.dispatch(actions.COMPLETE, { currentDrop, status: status.UNDELIVERABLE, signed: false, scanned: false });
    store.dispatch('info', 'Job reported as undeliverable.');
  });

  store.on(actions.SEND_SMS, async ({ deliveries }, payload) => {
    // payload will contain the `time` until the expected arrival and
    // the `phone` number we're sending to (because there could be
    // more than one mobile number provided). Also the deliveryRef.

    let smsResponse;
    try {
      smsResponse = await sendArrivalSms(payload); // should probably sanitise first
      smsResponse.success = true;
    } catch (err) {
      smsResponse = { success: false, error: err.message };
    }

    store.dispatch(_actions.SMS_SENT, smsResponse);
  });

  store.on(_actions.SMS_SENT, ({ deliveries }, payload) => {
    // update the customer record to show that the text has been sent (providing
    // there wasn't an error returned - in that case, we'll be notifying the user)
    if (payload.success) {
      const currentDrop = getCurrentDrop(deliveries);
      currentDrop.customer.smsCount++;
      const rtnObj = setCurrentDrop(deliveries, currentDrop);

      toast(`SMS sent to customer`);
      return rtnObj;
    }

    toast.error('Error reported sending SMS. Try again shortly.');
  });

  store.on(actions.UPDATE_ADVANCED, ({ deliveries }, payload) => {
    // used for the notes section of the delivery
    const currentDrop = getCurrentDrop(deliveries);
    currentDrop.notes = payload.notes;
    currentDrop.override = payload.override;
    const rtnObj = setCurrentDrop(deliveries, currentDrop);

    return rtnObj;
  });

  store.on(actions.VAN_SCAN, ({ deliveries }, payload) => {
    // called in from the manifest handler
    // payload contains item and data, we need to locate this in the drops and update accordingly

    // find payload.item.deliveryRef in the drops[].dropNo field
    const drops = cloneDeep(deliveries.drops);
    //const deliveryRef = payload.item.deliveryRef?.toString();
    //typeof payload.item.deliveryRef === 'string' ? payload.item.deliveryRef : payload.item.deliveryRef.toString();

    // todo: tidy the flip out of this
    const saleRef = payload.item.saleRef?.toString();
    const drop = drops[saleRef]; // nb we're working directly on the object here

    if (!drop) {
      // some kinda error
      console.error(`Could not find drop with saleRef ${saleRef}`);
      return;
    }

    // now match the stockNo to the item in the drop
    drop.items
      .filter(item => item.ean === payload.item.ean)
      .forEach(item => {
        // we're checking the stockNos, likely an array of one
        item.stockNos.forEach(record => {
          if (record.stockNo === payload.item.stockNo) {
            record.status = itemStatus.DESPATCHED; // I can't remember what status we need :/
          }
        });
      });

    // can we update the isDeliverable flag?
    drop.isDeliverable = isDropDeliverable(drop.items);

    // the current drop isn't getting automatically updated (boo) because
    // we're replacing the entire object... so we'll do it manually if needed

    // this was failing because saleRef is a string 🤦‍♂️
    if (deliveries.currentDrop.dropNo?.toString() === saleRef) {
      const currentDrop = cloneDeep(drop);
      return { deliveries: { ...deliveries, drops, currentDrop } };
    }

    // don't put an empty object in for currentDrop as I had been 🤦‍♂️
    return { deliveries: { ...deliveries, drops } };
  });

  store.on(actions.REORDER_DROPS, ({ deliveries, user, manifest }, payload) => {
    // payload will hold nextDropNo (the drop we want to be doing next).
    // we'll find that and move it into the place marked by dropManifestIndex,
    // recategorising the drops, then creating a call to the backend
    // (which requires customer ids rather than dropNos or order references)

    const { nextDropNo } = payload;
    const { drops, currentDrop } = deliveries;
    const dropManifest = [].concat(deliveries.dropManifest);
    //const currentDropIndex = dropManifest.indexOf(currentDrop.dropNo);
    const currentDropIndex = dropManifest.indexOf(getCurrentDropNo(deliveries));
    const nextDropIndex = dropManifest.indexOf(nextDropNo);

    if (nextDropIndex === -1) {
      // can't find the requested next drop for some reason
      console.error(`Could not find drop with dropNo ${nextDropNo}`);
      return;
    }

    // Pluck the drop we're moving from where we found it and insert it in the current position
    dropManifest.splice(nextDropIndex, 1);
    dropManifest.splice(currentDropIndex, 0, nextDropNo);

    const categorised = categoriseDrops({
      dropManifest,
      drops,
      dropManifestIndex: currentDropIndex,
      completedDrops: deliveries.completedDrops
    });

    // we've made our local updates, now send to the backend...
    // we need the manifest id, customer, date and sequence number

    const requestPayload = {
      manifestNo: manifest.manifestNo,
      action: 'reorderRoute',
      drops: []
    };

    const date = getToday();
    console.log(dropManifest);
    dropManifest.forEach((dropNo, idx) => {
      if (dropNo) {
        const rtnVal = {
          routeSequence: idx,
          dropNo,
          customerCode: drops[dropNo].customer.ref,
          date
        };

        requestPayload.drops.push(rtnVal);
      }
    });

    setCurrentDropNo(nextDropNo);

    store.dispatch(_actions.SEND_REORDERED_DROPS, { data: requestPayload });

    return { deliveries: { ...deliveries, dropManifest, ...categorised } };
  });

  store.on(_actions.SEND_REORDERED_DROPS, async ({ deliveries }, payload) => {
    try {
      const res = await rp3Post(payload.data);
      // inspect the response to ensure there's nothing funky coming back
      // and/or that there's an update to the manifest

      if (res.status !== 200) {
        throw new Error('Server responded with HTTP code ' + res.status);
      }

      if (!res.data.ok) {
        console.log(res.data);
        throw new Error('Server responded with ' + JSON.stringify(res.data));
      }

      if (res.data.getRoute) {
        // we'll need to update the manifest - there's been changes made in RP2
        toast.info('Route updated - fetching refreshed data');
        store.dispatch(manifestActions.GET_ROUTE);
      }
    } catch (err) {
      // some kind of failure - maybe a network issue.
      // we won't queue this one up because it could end up being sent out of order
      console.log(err);
      toast.error(`Error contacting server to confirm delivery order change.`);
    }
  });

  store.on(actions.STABBED, ({ deliveries }, payload) => {
    // find what has been passed through in the label within the stabbyButtons array
    // and move it over to the stabbedButtons array

    const currentDrop = getCurrentDrop(deliveries);
    const { item, accepted } = payload;

    Sentry.addBreadcrumb({
      category: 'deliveriesStore',
      message: 'Scanning data',
      level: 'info',
      data: payload
    });

    Sentry.addBreadcrumb({
      category: 'deliveriesStore',
      message: 'currentDrop clone',
      level: 'info',
      data: currentDrop
    });

    // we can add the accepted flag to the item for rendering in stabby component
    currentDrop.stabbyButtons = currentDrop.stabbyButtons.filter(record => record.deliveryRef !== item.deliveryRef);
    item.accepted = accepted;
    currentDrop.stabbedButtons = currentDrop.stabbedButtons.concat(item);

    // make sure that the button was located and updated
    if (currentDrop.stabbedButtons.length === deliveries.currentDrop.stabbedButtons.length) {
      console.error(`Couldn't find matching button (you should never see this)`);
      Sentry.captureMessage(`Apparently couldn't match the button stabbed`);
      store.dispatch('audio/error');
      return;
    }

    // if we can locate the item in the current drop, update its status to
    // either DELIVERED or UNDELIVERABLE with notes added to reflect that the
    // reject stabby button was pressed. the deliveryRef will let us do that.
    // we can't rely on the stockNo field itself as it can be blank, so for now
    // we'll just transform the status from picked to whatever. it's a bit sketchy
    // but that's true of much :/
    const activeItem = currentDrop.items.find(itemRecord => itemRecord.deliveryRef === item.deliveryRef);
    const stockRecord = activeItem?.stockNos.find(
      record =>
        record.status === itemStatus.PICKED ||
        record.status === itemStatus.PENDING ||
        record.status === itemStatus.DESPATCHED
    );

    if (stockRecord) {
      if (accepted) {
        stockRecord.status = itemStatus.SCANNED; // total misnomer now
      } else {
        stockRecord.status = itemStatus.UNDELIVERABLE;
        currentDrop.notes += '\n' + item.description + ' rejected by customer';
      }
    } else {
      // to help with Sentry
      console.error(`Couldn't find matching stock record`, item, activeItem);
    }

    //acknowledge the button press
    store.dispatch('audio/acknowledge');

    // now, see if this results in everything being 'scanned'
    currentDrop.scanned = isEverythingScanned(currentDrop);

    const rtnObj = setCurrentDrop(deliveries, currentDrop);
    return rtnObj;
  });

  store.on(actions.ADD_PHOTO, ({ deliveries }, payload) => {
    // we'll receive the photo blob in the payload.
    // we'll generate a UUID for the photo and add it to the current drop
    // we'll write the photo blob into local storage with the UUID as the key
    // payload also contains the dropNo, which we'll add to the key

    const currentDrop = getCurrentDrop(deliveries);
    const photoUUID = crypto.randomUUID();
    const { photo, dropNo } = payload;
    const photoURL = URL.createObjectURL(photo);
    const key = `${PHOTO_PREFIX}:${getToday()}:${photoUUID}:${dropNo}`;
    currentDrop.photos.push({
      uuid: photoUUID,
      url: photoURL,
      key
    });

    // write the photo to localforage
    localforage.setItem(key, photo).catch(err => {
      console.error(err);
      Sentry.captureException(err);
      toast.error('Error writing photo to internal storage');
    });

    const rtnObj = setCurrentDrop(deliveries, currentDrop);
    return rtnObj;
  });

  store.on(actions.REMOVE_PHOTO, ({ deliveries }, payload) => {
    // makes the remove button on the thumbnails work
    const currentDrop = getCurrentDrop(deliveries);
    const uuid = payload.uuid;
    let key;
    currentDrop.photos = currentDrop.photos.filter(photo => {
      if (photo.uuid !== uuid) {
        return true;
      }
      key = photo.key;
      return false;
    });

    // remove the photo from localforage
    localforage.removeItem(key);
    const rtnObj = setCurrentDrop(deliveries, currentDrop);
    return rtnObj;
  });

  store.on(actions.UPDATE_SIGNATURE_NAME, ({ deliveries }, { name }) => {
    const currentDrop = getCurrentDrop(deliveries);
    currentDrop.signatureName = name;
    const rtnObj = setCurrentDrop(deliveries, currentDrop);
    return rtnObj;
  });

  // we'll use this when we enter the delivery screen and the number of photos in
  // the current drop is 0, in case there's some in localforage and somehow we've
  // lost the reference to them.
  store.on(actions.CHECK_FOR_PHOTOS, async ({ deliveries }) => {
    const currentDrop = getCurrentDrop(deliveries);
    if (currentDrop.photos.length === 0) {
      const keys = await localforage.keys();
      keys
        .filter(key => key.startsWith(PHOTO_PREFIX) && key.endsWith(currentDrop.dropNo.toString()))
        .forEach(async key => {
          const uuid = key.split(':')[2];
          const photo = await localforage.getItem(key);
          const payload = {
            uuid,
            key,
            url: URL.createObjectURL(photo)
          };
          store.dispatch(_actions.ADD_LOST_PHOTO, payload);
        });
    }
  });

  store.on(_actions.ADD_LOST_PHOTO, ({ deliveries }, payload) => {
    // do what we need to
    const currentDrop = getCurrentDrop(deliveries);
    console.log(payload);
    currentDrop.photos.push(payload);
    const rtnObj = setCurrentDrop(deliveries, currentDrop);
    return rtnObj;
  });

  store.on(actions.MISSING_RECORD_UPLOADED, ({ deliveries }, { dropNo }) => {
    // see if the delivery referred to in the payload is currently in the
    // store and, if so, ensure its status change is reflected.
    // nb: this will ONLY set a record to being uploaded. other values are absent.
    // it's hard to predict wtf happens out on the field.
    if (deliveries.drops[dropNo]) {
      const drops = cloneDeep(deliveries.drops);
      drops[dropNo].status = status.DELIVERED;
      return { deliveries };
    }
  });

  store.on(actions.SET_ACTIVE_DROP_NO, ({ deliveries }, dropNoStr) => {
    // this gets called as the delivery page is mounted and is part of the
    // continuing saga of trying to get this fscking thing to work right
    const dropNo = parseInt(dropNoStr);

    // if it's not in the current manifest, bail
    const dropManifestIndex = deliveries.dropManifest.indexOf(dropNo);
    if (dropManifestIndex === -1) {
      console.error(`trying to make ${dropNoStr} active when it's not in the manifest`);
      return;
    }

    setCurrentDropNo(dropNoStr); // string is fine where it's going
    return { deliveries: { ...deliveries, dropManifestIndex } };
  });
}

// @param {object} deliveries - the deliveries store
// @returns {object} - clone of the current drop from the drops object in the deliveries store
function getCurrentDrop(deliveries) {
  const currentDropNo = getCurrentDropNo(deliveries);
  // console.log('getCurrentDropNo returned ' + currentDropNo);
  if (!currentDropNo) {
    return {};
  }
  const rtnVal = cloneDeep(deliveries.drops[currentDropNo]);

  return rtnVal;
}

// @param {object} deliveries - the deliveries store as it stands
// @param {object} currentDrop - the state of the currently active drop, for insertion
// @returns {object} - the cloned deliveries store with the correct drop updated
function setCurrentDrop(deliveries, currentDrop) {
  const currentDropNo = getCurrentDropNo(deliveries);
  if (!currentDropNo) {
    console.error(`Couldn't find current drop no!`);
    return deliveries;
  }
  const cloneDeliveries = cloneDeep(deliveries);
  cloneDeliveries.drops[currentDropNo] = currentDrop;
  cloneDeliveries.currentDrop = currentDrop; // for access from elsewhere
  return { deliveries: cloneDeliveries };
}

function getCurrentDropNo(deliveries) {
  // if what's in localstorage is in the dropManifest we should be able to assume that it's valid
  const lsDropNo = parseInt(window?.localStorage.getItem('currentDropNo'));
  if (deliveries.dropManifest.includes(lsDropNo)) {
    //console.log('ls ' + lsDropNo);
    return lsDropNo;
  }

  Sentry.addBreadcrumb({
    category: 'deliveriesStore',
    message: 'getCurrentDropNo',
    level: 'info',
    data: { manifest: deliveries.dropManifest, index: deliveries.dropManifestIndex }
  });
  //console.log('Getting currentDropNo from manifest');
  return deliveries.dropManifest[deliveries.dropManifestIndex];
}

function setCurrentDropNo(dropNo) {
  //console.log('ls set ' + dropNo);
  window?.localStorage.setItem('currentDropNo', dropNo);
  Sentry.setTag('drop_no', dropNo);
}

function isEverythingScanned(currentDrop) {
  // effectively saying everything has been handled - no barcodes required and
  // no stabby buttons left behind
  return currentDrop.requiredBarcodes.length === 0 && currentDrop.stabbyButtons.length === 0;
}

function categoriseDrops({ drops, dropManifest, dropManifestIndex, completedDrops, removeMatchingFails = false }) {
  const rtnVal = {
    currentDrop: null,
    upcomingDrops: [],
    completedDrops: []
  };

  for (let i = 0, n = dropManifest.length; i < n; i++) {
    const dropNo = dropManifest[i];
    if (dropNo) {
      // means we can sidestep any undefineds if the array is sparse for some reason
      let dropStatus = lsCheckIsNew(dropNo);
      if (removeMatchingFails && dropStatus === recordStatus.FAILED) {
        lsRemoveFail(dropNo);
        dropStatus = recordStatus.NEW;
        drops[dropNo].rescheduled = true;
      }
      if (dropStatus === recordStatus.EXISTING || dropStatus === recordStatus.FAILED) {
        // a record in storage - definitely completed but failed ones may come back in rescheduling
        rtnVal.completedDrops.push(dropNo);
        if (dropManifestIndex <= i) {
          console.error(`dropManifestIndex problem`);
          dropManifestIndex++; // papering over the cracks a bit
        }
      } else if (i > dropManifestIndex) {
        rtnVal.upcomingDrops.push(dropNo);
      } else if (i === dropManifestIndex) {
        rtnVal.currentDrop = cloneDeep(drops[dropNo]);
      }
    }
  }

  // mix completedDrops and rtnVal.completedDrops, removing duplicates and
  // excluding any that are not from today (to clear out old drops)
  const today = getToday();
  rtnVal.completedDrops = rtnVal.completedDrops.concat(
    cloneDeep(
      completedDrops
        .filter(drop => drop.date === today)
        .filter(drop => !rtnVal.completedDrops.find(completedDrop => completedDrop.dropNo === drop.dropNo))
    )
  );

  // whilst we're clearing up (and abusing this function) we can clear out the photos
  // in localforage that are over a week old. This routine has been written by AI, quite impressive!
  localforage.keys().then(keys => {
    keys.forEach(key => {
      if (key.startsWith(PHOTO_PREFIX) || key.startsWith(DATA_PREFIX)) {
        const date = key.split(':')[1];
        if (date !== today) {
          // convert the date to a luxon date object and see if it's more than a week old
          const age = Math.abs(DateTime.fromISO(date).diffNow().as('days'));
          if (age > 7) {
            localforage
              .removeItem(key)
              .then(() => console.log(`removed ${key} (aged ${age} days)`))
              .catch(err => console.error(err));
          }
        }
      }
    });

    // and one more abuse of this function - clearing out >24 hour records
    lsPurge();
  });

  return rtnVal;
}

function isDropDeliverable(itemData) {
  return itemData.every(item => {
    // only look at records that need to be actioned
    if (item.showStabbyButton) return true;

    // deal with zero quantity funkiness - repairs can trip that up -
    // and also negative quantities for swapping out
    if (item.quantity <= 0) return true;

    // make sure there's enough stock
    if (item.stockNos.length !== item.quantity) return false;

    // handle collection/disposal charges etc
    if (!item.serials && item.ean === '') return true;
    // console.log('looking for non despatched in itemData', item.stockNos);
    // if we've made it this far, check everything is marked as despatched
    return item.stockNos.every(record => record.status === itemStatus.DESPATCHED);
  });
}

function selectCompleteStatus(itemData) {
  // look at the items in the order - and the number of items in the delivery -
  // to work out what the status of the delivery should be set to
  let currentVal = status.DELIVERED;

  itemData.forEach(item => {
    item.stockNos.forEach(record => {
      if (record.status === itemStatus.UNDELIVERABLE) {
        if (currentVal === status.DELIVERED) {
          currentVal = status.UNDELIVERABLE;
        }
      } else if (currentVal === status.UNDELIVERABLE) {
        // we're here if this item isn't undeliverable, but we've already
        // found an undeliverable item - so we need to change the status
        currentVal = status.PART_DELIVERED;
      }
    });
  });
  // console.log('selectCompleteStatus', currentVal);
  return currentVal;
}

function getToday() {
  return DateTime.now().toISODate();
}

// localstorage functions for keeping data clean

// Make sure this hasn't already been processed
// @param {*} dropNo delivery reference to check
// @returns {recordStatus.*}
function lsCheckIsNew(dropNo) {
  if (typeof window === 'undefined') {
    return true; // just for the generation
  }

  if (window.localStorage.getItem('fail:' + dropNo) !== null) {
    // previously failed and rescheduled (presumably)
    // so remove that localStorage item and return value so that
    // arrangements can be made wrt the stock in the van
    //window.localStorage.removeItem('fail:' + dropNo);
    return recordStatus.FAILED;
  }

  // not sure what to do here with regards to failed deliveries...
  // but this is the issue that keeps giving them trouble ¯\_(ツ)_/¯
  return window.localStorage.getItem('drop:' + dropNo) === null ? recordStatus.NEW : recordStatus.EXISTING;
}

function lsSet(dropNo, success = true) {
  const key = (success ? 'drop:' : 'fail:') + dropNo;
  console.log('Setting', key, 'success was', success);
  window?.localStorage.setItem(key, Date.now() + 86400000); // a day in ms
}

function lsRemoveFail(dropNo) {
  window?.localStorage.removeItem('fail:' + dropNo);
}

// This just keeps the localStorage tidy
function lsPurge() {
  if (typeof window === 'undefined') {
    return;
  }

  const now = Date.now();

  Object.keys(window.localStorage).forEach(key => {
    if (key.startsWith('drop:') || key.startsWith('fail:')) {
      const val = window.localStorage.getItem(key);
      if (val < now) {
        console.log('removing', key);
        window.localStorage.removeItem(key);
      }
    }
  });
}
