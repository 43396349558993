import { h, Component } from 'preact';

import { bind } from 'decko';
import { connectStoreon } from 'storeon/preact';
import { route } from 'preact-router';

import { Navbar, NavbarBrand, Button } from 'reactstrap';
import Icon from '../icon';
import { actions } from '../../store/manifest';
import styles from './style.scss';
import { displayNotification } from '../../util/notification';
import { messageSW } from '../../util/serviceWorker';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      online: navigator && navigator.onLine,
      queueSize: 0
    };

    if (typeof window !== 'undefined') {
      // handle network status changes
      console.log('adding the online/offline event listener');
      window.addEventListener('online', async () => {
        this.setState({ online: true });
        const queueSize = await this.updateQueueSize();
        if (queueSize > 0) {
          messageSW({ type: 'REPLAY_QUEUE' });
          displayNotification('Back online - uploading queued data...');
        }
      });
      window.addEventListener('offline', () => {
        console.log('offline');
        this.setState({ online: false });
      });
    }
  }

  @bind
  async updateQueueSize() {
    const { payload } = await messageSW({ type: 'GET_QUEUE_SIZE' });
    this.setState({ queueSize: payload.length });
    return payload.length;
  }

  @bind
  goToSettings() {
    route('/settings', true);
  }

  @bind
  async goToHome() {
    this.updateQueueSize();

    if (this.props.currentUrl !== '/' && this.props.user.valid) {
      route('/', true);
    }
  }

  @bind
  goToLoadVan() {
    if (this.props.currentUrl !== '/load' && this.props.user.valid) {
      route('/load', true);
    }
  }

  @bind
  goToInspector() {
    if (this.props.currentUrl !== '/inspector' && this.props.user.valid) {
      route('/inspector', true);
    }
  }

  @bind
  refreshFeed() {
    this.props.dispatch(actions.REFRESH_DATA);
  }

  render(props, state) {
    const { currentUrl, manifest, user } = props;
    let title;
    if (user && user.terminalId) {
      title = `HBH Woolacotts Delivery - ${user.terminalId}`;
    } else {
      title = 'HBH Woolacotts Delivery';
    }
    if (_IS_DEMO_) {
      title += ' [D]';
    }
    return (
      <Navbar light color={currentUrl === '/load' ? 'info' : 'warning'} className={styles.navbar}>
        <NavbarBrand onClick={this.goToHome}>
          <span>{title}</span>
        </NavbarBrand>
        {!state.online && <Icon style={{ flexGrow: 1 }}>signal_cellular_connected_no_internet_0_bar</Icon>}
        <div class={styles.buttons}>
          {currentUrl !== '/load' && manifest.hasRoute && !manifest.allScanned && (
            <Button onClick={this.goToLoadVan}>
              <Icon>input</Icon>
            </Button>
          )}

          {currentUrl === '/load' && (
            <Button onClick={this.goToHome}>
              <Icon>local_shipping</Icon>
            </Button>
          )}

          {currentUrl === '/' && (
            <Button onClick={this.refreshFeed}>
              <Icon>sync</Icon>
            </Button>
          )}

          {currentUrl !== '/settings' && (
            <Button onClick={this.goToSettings}>
              <Icon>settings</Icon>
            </Button>
          )}
        </div>
      </Navbar>
    );
  }
}

export default connectStoreon('user', 'manifest', Header);
