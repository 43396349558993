import { h, Component, createRef } from 'preact';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { connectStoreon } from 'storeon/preact';
import style from './style.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeepSound from './beep.mp3';
import ErrorSound from './error.mp3';
import AcknowledgeSound from './acknowledge.mp3';

class UserFeedback extends Component {
  constructor(props) {
    super(props);
    this.beepAudio = createRef();
    this.errorAudio = createRef();
    this.acknowledgeAudio = createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.feedback.beepTimestamp !== this.props.feedback.beepTimestamp) {
      this.beepAudio.current.play();
    }

    if (prevProps.feedback.errorTimestamp !== this.props.feedback.errorTimestamp) {
      this.errorAudio.current.play();
    }

    if (prevProps.feedback.acknowledgeTimestamp !== this.props.feedback.acknowledgeTimestamp) {
      this.acknowledgeAudio.current.play();
    }
  }

  confirmFeedback = () => this.props.dispatch('feedback/confirmed');

  render(props) {
    const isError = props.feedback.type === 'error';
    const className = isError ? style.error : style.info;
    const title = isError ? 'Error' : 'HBH Woolacotts Paperless Delivery';

    return (
      <>
        <Modal toggle={this.confirmFeedback} isOpen={!props.feedback.confirmed} backdrop="static" className={className}>
          <ModalHeader toggle={this.confirmFeedback} className={style.header}>
            {title}
          </ModalHeader>
          <ModalBody>{props.feedback.message}</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.confirmFeedback}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <audio ref={this.beepAudio} crossOrigin="anonymous" src={BeepSound} type="audio/mpeg" />
        <audio ref={this.errorAudio} crossOrigin="anonymous" src={ErrorSound} type="audio/mpeg" />
        <audio ref={this.acknowledgeAudio} crossOrigin="anonymous" src={AcknowledgeSound} type="audio/mpeg" />

        <ToastContainer autoClose={5000} position="bottom-center" closeOnClick newestOnTop />
      </>
    );
  }
}

export default connectStoreon('feedback', UserFeedback);
