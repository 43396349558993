export async function displayNotification(message) {
  if (typeof Notification === 'undefined') {
    // can't do anything if notifications aren't supported
    // (basically iOS Safari and SSR)

    return;
  }

  if (Notification.permission === 'granted') {
    // all a bit hardcoded at the moment
    const reg = await navigator.serviceWorker.getRegistration();
    const options = {
      body: message,
      icon: '/assets/icons/android-chrome-192x192.png',
      tag: 'general-woolacotts', // so that we don't fill the notification area and annoy users
      silent: true // also avoid annoying users by vibrating all the time
    };
    reg.showNotification('HBH Woolacotts', options);
  }
}
