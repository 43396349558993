import * as Sentry from '@sentry/browser';

// we'll export this so that the settings screen can know to disable when absent
export const hasBarcodeDetector = typeof window === 'object' && typeof window.BarcodeDetector === 'function';

const startState = {
  user: {
    terminalId: '',
    valid: false,
    useRemote: false,
    secret: 'terminal access key',
    autoscan: true,
    autoGoogleClose: true,
    focusDistance: 0,
    focusLock: false,
    nativeScanning: hasBarcodeDetector,
    rp3Api: _RP3_,
    photoApi: _PHOTO_API_
  }
};

export const actions = {
  SET_TERMINAL_ID: 'user/setTerminalId',
  SET_SECRET: 'user/setSecret',
  SET_REMOTE: 'user/setRemote',
  SET_AUTOSCAN: 'user/setAutoscan',
  SET_AUTO_GOOGLE_CLOSE: 'user/setAutoGoogleClose',
  SET_FOCUS_DISTANCE: 'user/setFocusDistance',
  SET_FOCUS_LOCK: 'user/setFocusLock',
  SET_NATIVE_SCANNING: 'user/setNativeScanning',
  SET_WHOLE_STATE: 'user/setWholeState'
};

export function user(store) {
  store.on('@init', () => startState);
  store.on('clearAll', () => startState);

  store.on(actions.SET_TERMINAL_ID, ({ user }, payload) => {
    let terminalId = payload.terminalId || '';
    terminalId = terminalId.trim();
    Sentry.setUser({ username: terminalId });
    return { user: { ...user, terminalId, valid: terminalId !== '' } };
  });

  store.on(actions.SET_FOCUS_DISTANCE, ({ user }, focusDistance) => ({
    user: { ...user, focusDistance }
  }));

  store.on(actions.SET_FOCUS_LOCK, ({ user }, focusLock) => ({
    user: { ...user, focusLock }
  }));

  store.on(actions.SET_REMOTE, ({ user }, useRemote) => ({
    user: { ...user, useRemote }
  }));

  store.on(actions.SET_SECRET, ({ user }, payload) => ({
    user: { ...user, secret: payload.secret }
  }));

  store.on(actions.SET_AUTOSCAN, ({ user }, autoscan) => ({
    user: { ...user, autoscan }
  }));

  store.on(actions.SET_NATIVE_SCANNING, ({ user }, nativeScanning) => ({
    user: { ...user, nativeScanning }
  }));

  store.on(actions.SET_AUTO_GOOGLE_CLOSE, ({ user }, autoGoogleClose) => ({
    user: { ...user, autoGoogleClose }
  }));

  store.on(actions.SET_WHOLE_STATE, ({ user }, payload) => ({
    user: { ...user, ...payload }
  }));
}
