/* eslint-disable no-undef */
import { createStoreon } from 'storeon';
import { persistState } from '@storeon/localstorage';
import { storeonDevtools } from 'storeon/devtools';

import { user } from './user';
import { manifest } from './manifest';
import { deliveries } from './deliveries';
import { feedback } from './feedback';

export const store = createStoreon([
  user,
  manifest,
  deliveries,
  feedback,
  !_IS_PRODUCTION_ && storeonDevtools,
  persistState(['user', 'manifest', 'deliveries'], { key: 'woolacotts-delivery' })
]);
