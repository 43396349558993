import { h, Component } from 'preact';
import { Router, route } from 'preact-router';
import { StoreContext } from 'storeon/preact';
import localforage from 'localforage';
import * as Sentry from '@sentry/browser';
import { store } from '../store';
import Header from './header';
import Helmet from 'preact-helmet';
import UserFeedback from './feedback';
import PageVisibility from './pageVisibility';
import ShutdownTriggerWorker from '../workers/shutdownTrigger.worker';
import { actions as UserActions } from '../store/user';
import rfdc from 'rfdc';
// Code-splitting is automated for routes
import Home from '../routes/home'; // at the moment all logic is hither
import Settings from '../routes/settings';
import Delivery from '../routes/delivery';
import Undeliverable from '../routes/undeliverable';
import Details from '../routes/details';
import LoadVan from '../routes/loadvan';
import Test from '../routes/test';
import Inspector from '../routes/inspector';

const cloneDeep = rfdc();

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = { currentUrl: '' };

    Sentry.setTag('git_hash', _GIT_HASH_);
    Sentry.setTag('build_time', _BUILD_TIME_);

    if (typeof window !== 'undefined') {
      // we're in the browser, so we need to start the shutdown trigger worker
      this.worker = new ShutdownTriggerWorker();
      this.worker.addEventListener('message', ev => {
        if (ev.data === 'shutdown') {
          this.worker.terminate(); // next time I do this I'll have a broadcast system
          window.close();
        }
      });
      this.worker.postMessage('start');
    }
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
    if (this.worker) {
      this.worker.terminate();
    }
  }

  /** Gets fired when the route changes.
   *  @param {Object} event     "change" event from [preact-router](http://git.io/preact-router)
   *  @param {string} event.url The newly routed URL
   */
  handleRoute = e => {
    const user = store.get().user;
    const url = e.url.split('?')[0];

    if (url === '/reset') {
      // retain certain/all values from user store
      window?.localStorage.removeItem('woolacotts-delivery');
      localforage.clear();
      userBackup = cloneDeep(user);
      userBackup.rp3Api = '';
      userBackup.photoApi = '';
      store.dispatch('@init');
      store.dispatch(UserActions.SET_WHOLE_STATE, user);
      route('/settings', true);
      return;
    }

    if (!user.valid && url !== '/settings') {
      route('/settings', true);
      return;
    }

    Sentry.setUser({ username: user.terminalId });
    this.currentUrl = e.url;
    this.setState({ currentUrl: this.currentUrl });
  };

  handlePageVisibility = (isVisible, status) => {
    //console.log('Page visibility changed', isVisible, status);
  };

  render(props, state) {
    return (
      <PageVisibility onChange={this.handlePageVisibility}>
        <div id="app">
          <StoreContext.Provider value={store}>
            <Helmet
              meta={[
                {
                  name: 'viewport',
                  content: 'initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no'
                }
              ]}
            />
            <Header currentUrl={state.currentUrl} />
            <Router onChange={this.handleRoute}>
              <Home path="/" />
              <Delivery path="/delivery/:ref" />
              <Details path="/details/:ref" />
              <Undeliverable path="/undeliverable/:ref/:source?" />
              <Settings path="/settings" />
              <LoadVan path="/load" />
              <Test path="/test" />
              <Inspector path="/inspector" />
            </Router>
            <UserFeedback />
          </StoreContext.Provider>
        </div>
      </PageVisibility>
    );
  }
}
