// Moved this from the deliveries store so that the functionality can
// (potentially) be used elsewhere

const LAST_ROUTE_CHANGE = 'lastRouteChange';

/**
 * Checks to see if the date provided for the last change from the server is different
 * to when it was last checked, and responds accordingly.
 * @param {String} timestamp The string of the server time
 * @returns {Boolean} response to the question
 */
export function hasRouteChanged(timestamp) {
  if (typeof window === 'undefined') {
    return true;
  }

  return timestamp !== window.localStorage.getItem(LAST_ROUTE_CHANGE);
}

export function setLastRouteChange(timestamp) {
  window?.localStorage.setItem(LAST_ROUTE_CHANGE, timestamp);
}

export function purgeRouteChangeData() {
  window?.localStorage.removeItem(LAST_ROUTE_CHANGE);
}
