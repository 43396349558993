import { h } from 'preact';
import style from './style.scss';

const Icon = props => {
  return (
    <i class={[style.icon].concat(props.class || []).join(' ')} style={props.style}>
      {props.children}
    </i>
  );
};

export default Icon;
