/* eslint-disable no-undef */
import axios from 'axios';
import { store } from '../store';
import * as Sentry from '@sentry/browser';

function getGonkParameters() {
  const photoApiBase = _IS_DEMO_ ? store.get().user.photoApi || _PHOTO_API_ : _PHOTO_API_;
  const photoApi = new URL('/photo', photoApiBase).href;
  const recordCheckApi = new URL('/record-check/', photoApiBase).href; // note trailing slashes here
  const fullRecordCheckApi = new URL('/barcode/', photoApiBase).href;
  return { photoApi, recordCheckApi, fullRecordCheckApi };
}

function getParameters() {
  const rp3Api = _IS_DEMO_ ? store.get().user.rp3Api || _RP3_ : _RP3_;
  let { useRemote, secret, terminalId } = store.get().user;
  useRemote = useRemote && _IS_PRODUCTION_;
  const url = useRemote ? _PROXY_ : rp3Api;
  return { url, useRemote, secret, terminalId };
}

export function rp3Get(params) {
  const { url, useRemote, secret, terminalId } = getParameters();
  params.terminalId = terminalId;
  if (useRemote) {
    params.secret = encodeURIComponent(secret); // plain text, not a proper password
  }
  return axios.get(url, { params });
}

export function rp3Post(data) {
  const { url, useRemote, secret, terminalId } = getParameters();
  const params = { terminalId };

  Sentry.addBreadcrumb({
    category: 'myAxios',
    message: 'rp3Post data',
    level: 'info',
    data
  });

  if (useRemote) {
    params.secret = secret;
  }
  return axios.post(url, data, { params });
}

export async function isPhotoUploaded({ barcode, uuid }) {
  const { recordCheckApi } = getGonkParameters();
  try {
    // if this succeeds, we can assume the photo has already been uploaded. we'll have
    // a quick check that the barcode matches though.
    const checkData = await axios.get(recordCheckApi + uuid);
    //const checkData = await axios.get(`${_RECORD_CHECK_API_}/${uuid}`);
    if (checkData.data.barcode === barcode) {
      // don't need to upload anything
      return true;
    }
  } catch (err) {
    if (err.response && err.response.status !== 404) {
      // we only want a 404 here - nothing else like 500s etc
      throw err;
    }
  }
  return false;
}

export async function getFullReport({ barcode }) {
  const { fullRecordCheckApi } = getGonkParameters();
  try {
    const data = await axios.get(fullRecordCheckApi + barcode);
    //const data = await axios.get(`${_FULL_RECORD_CHECK_API_}/${barcode}`);
    return { success: true, ...data };
  } catch (err) {
    return { success: false };
  }
}

export async function uploadPhoto({ barcode, photo, uuid }) {
  // check with the server to see if a record with the 'md5' already exists and if so,
  // don't try uploading it again.

  const isUploaded = await isPhotoUploaded({ barcode, uuid });
  if (isUploaded) {
    return;
  }

  Sentry.addBreadcrumb({
    category: 'myAxios',
    message: 'uploadPhoto params',
    level: 'info',
    data: { barcode, photo, uuid }
  });

  const { terminalId } = getParameters();
  const { photoApi } = getGonkParameters();
  //const url = _PHOTO_API_;
  const form = new FormData();
  form.append('barcode', barcode); // old parlance
  form.append('user', terminalId);
  form.append('md5', uuid); // no it's not actually an md5 hash but it should be unique and help me debug
  form.append('installationId', _GIT_HASH_); // bit more accurate name than 'md5'
  form.append('photo', photo, 'Job photo'); // has to be the final item it seems

  return axios.post(photoApi, form, {
    headers: _PHOTO_API_HEADER_
  });
}
