import './style/index.scss';
import App from './components/app';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import localforage from 'localforage';

Sentry.init({
  // eslint-disable-next-line no-undef
  dsn: _SENTRY_,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.05
});

localforage.config({
  name: 'HBH Woolacotts'
});

// ask for notification permissions
if (typeof Notification !== 'undefined' && Notification.permission !== 'granted') {
  Notification.requestPermission();
}

export default App;
